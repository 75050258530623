import { gql, useQuery, useLazyQuery, QueryHookOptions } from '@apollo/client';

import { Query, QueryJobsArgs } from '../../generated/types';

export const GET_JOBS = gql`
  query getJobs($where: JobsInputWhere!, $sort: InputSort!, $limit: Int) {
    jobs(where: $where, sort: $sort, limit: $limit) {
      uuid

      status
      feeling
      temperature

      board {
        uuid
      }

      isThroughAgency
      agencyName
      agentName
      agentEmail
      agentPhone
      referralFee

      company
      companyInfo
      companyWebsite
      companyLinkedIn
      companyAvatar
      companyLocation
      companyLocationCity
      companyLocationCountry
      companyLocationLatitude
      companyLocationLongitude
      companyLocationPostCode
      companyLocationStreet
      companyLocationUrl
      companyDistances

      jobTitle
      jobSeniorityLevel
      jobDescription
      jobRequirement
      jobUrl

      contacts {
        uuid
        fullName
        position
        email
        phoneNumber
        linkedin
      }

      events {
        uuid
        type
        description
        createdAt
      }

      ir35
      duration
      rate
      employmentType
      remoteOption

      isOwner
      owner {
        uuid
        email
        nickname
        name
      }

      createdBy {
        uuid
        email
        nickname
        name
      }

      updatedBy {
        uuid
        email
        nickname
        name
      }

      createdAt
      updatedAt
      appliedAt
      interviewedAt
      offerAt
      rejectedAt
      acceptedAt

      permissions
      aiMatchingSkillsPercentage

      isNew
      isDeleted
    }
  }
`;

export const useGetJobs = (options?: QueryHookOptions<Pick<Query, 'jobs'>, QueryJobsArgs>) =>
  useQuery<Pick<Query, 'jobs'>, QueryJobsArgs>(GET_JOBS, options);

export const useLazyGetJobs = (options?: QueryHookOptions<Pick<Query, 'jobs'>, QueryJobsArgs>) =>
  useLazyQuery<Pick<Query, 'jobs'>, QueryJobsArgs>(GET_JOBS, options);
